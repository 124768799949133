import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
query Getmusicurl {
    site {
        siteMetadata {
          api {
            main_url
            default_version
            music_uri
          }
        }
      }
    }
`
// const data = useStaticQuery(query)
// const def_url = (api) => `${api.main_url}${api.default_version}${api.music_uri}`

const BASE_API_ROUTE = process.env.GATSBY_BASE_API_ROUTE || `https://api.eevnxx.tk/v3`;

const GetGuildMusic = (guild_id, setQueue) => {
    const url = BASE_API_ROUTE + `/discord/music/${guild_id}`;
    // const url = `${def_url(data)}/${guild_id}`;

    fetch(url, {
        credentials: 'include'
    })
        .then(res => {
            if (res.status >= 200 && res.status < 300) { }
            else { }
            return res
        })
        .then(response => response.json())
        .then(resultData => {
            setQueue(resultData)
        })
        .catch(err => console.log(err))
}

const DeleteGuildMusic = (guild_id) => {
    const url = BASE_API_ROUTE + `/discord/music/${guild_id}`;

    fetch(url, {
        credentials: 'include',
        method: 'DELETE'
    })
        .then(res => {
            if (res.status >= 200 && res.status < 300) { }
            else { }
            return res
        })
        .catch(err => console.log(err))
}

const _PutGuildMusic = (guild_id, method) => {
    // const url = `${def_url(data)}/${guild_id}`;
    const url = BASE_API_ROUTE + `/discord/music/${guild_id}`;

    fetch(url, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify({method}),
        headers: {'Content-Type': 'application/json'}
    })
        .then(res => {
            if (res.status >= 200 && res.status < 300) { }
            else { }
            return res
        })
        .catch(err => console.log(err))
}

const Toggle = (guild_id) => { return _PutGuildMusic(guild_id, 'TOGGLE'); }
const Next = (guild_id) => { return _PutGuildMusic(guild_id, 'NEXT'); }
const Previous = (guild_id) => { return _PutGuildMusic(guild_id, 'PREVIOUS'); }

export default GetGuildMusic

export { DeleteGuildMusic as Delete, Toggle, Next, Previous }