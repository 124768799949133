import React, { useState, useEffect, useContext, useLayoutEffect } from "react"

import AuthContext from "../../../context/AuthContext"
import Get, { Delete, Next, Previous, Toggle } from "../../../services/music";

import sound from "../../../assets/5-seconds-of-silence.mp3"

import Layout from '../../../components/customlayout'


const AnyGuildMusicPage = ({ location }) => {
    const route_id = location.pathname.split('/dashboard/music/')[1];

    //media shit
    const MEDIA_SUPPORT = typeof navigator !== `undefined`;
    if (MEDIA_SUPPORT) {

        navigator.mediaSession.setActionHandler('play', () => {
            Toggle(guild.id);
            navigator.mediaSession.playbackState = "playing";
        });

        navigator.mediaSession.setActionHandler('pause', () => {
            Toggle(guild.id);
            navigator.mediaSession.playbackState = "paused";
        });

        navigator.mediaSession.setActionHandler('stop', () => {
            Delete(guild.id);
            navigator.mediaSession.playbackState = "paused";
        });
        navigator.mediaSession.setActionHandler('seekbackward', () => { });
        navigator.mediaSession.setActionHandler('seekforward', () => { });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
            Previous(guild.id);
        });

        navigator.mediaSession.setActionHandler('nexttrack', () => {
            Next(guild.id);
        });
    }

    const setMedia = () => {
        const BASE_URL = 'https://cdn.discordapp.com/avatars/297343587538960384/dc0f6c55dccce906595c652790bc00bf.png';

        navigator.mediaSession.metadata = new window.MediaMetadata({
            title: nowPlaying,
            artist: 'Discordbot Media Player',
            album: 'no album specified',
            artwork: [
                { src: BASE_URL + '?size=128', sizes: '128x128', type: 'image/png' },
                { src: BASE_URL + '?size=256', sizes: '256x256', type: 'image/png' },
                { src: BASE_URL + '?size=512', sizes: '512x512', type: 'image/png' },
            ]
        })
        navigator.mediaSession.playbackState = "playing";
    }

    const [alertType, setAlertType] = useState();
    const MainAlert = () => (
        <div className="alert alert-warning" role="alert">
            Remember, this is beta feature. There could be any bug that break UI entirely.
        </div>
    )
    const SuccessAlert = () => (
        <div className="alert alert-success" role="alert">
            Successfully dispatched command.
        </div>
    )
    const DangerAlert = () => (
        <div className="alert alert-danger" role="alert">
            Unable to dispatch requested command.
        </div>
    )

    const [validGuild, setValidGuild] = useState(false);
    const [guild, setGuild] = useState({ id: route_id });
    const [queue, setQueue] = useState({});
    const { isLogged, me, refreshMutual } = useContext(AuthContext);

    const [oncePageLoaded, setOPL] = useState(false);

    useEffect(() => {
        CheckGuildId(location, isLogged, me, setValidGuild, setGuild);
    }, [isLogged, me, me.mutualGuilds.length])

    useLayoutEffect(() => {
        if (!oncePageLoaded) {
            refreshMutual(() => {
                setOPL(true);
            });
            Get(guild.id, setQueue)
        }

    }, [oncePageLoaded])

    useEffect(() => {
        setNowPlaying(queue?.current_track?.title || 'No Song Here')
    }, [queue])

    let message = 'loading guild...';
    if (!validGuild) message = 'no guild found';
    if (!isLogged) message = 'login required';

    const SecondAlert = () => {
        if (alertType === 'success') return SuccessAlert()
        if (alertType === 'danger') return DangerAlert()
    }

    const [nowPlaying, setNowPlaying] = useState('ZIRRVAA');


    return (
        <Layout>
            <main className='container'>
                {/* {Array(...Array(alertCount)).map(_ => (<Alert />))} */}
                {MainAlert()}
                {SecondAlert()}
                {validGuild ?
                    (<>
                        <h1 className="display-1 text-bold text-white">{guild.name}</h1>
                        <div className="container py-4">
                            <span className="badge badge-info bg-info" style={{}}>Beta</span>
                            <h1>NOW PLAYING</h1>
                            <h2> {queue.current_track?.title} </h2>
                            {/* <button onClick={() => setAlertCount(alertCount + 1)} className="btn btn-primary">alert</button> */}
                        </div>
                        <div className="container py-4">
                        <div className="p-5 mb-4 bg-success text-white rounded-3">
                            <span className="badge badge-danger bg-danger" style={{}}>{ queue?.upcoming?.length || 0}</span>
                            <h1>UPCOMING</h1>
                            <h2> {queue?.upcoming?.slice(0, 5).map(track=> <h2> {track?.title} </h2>) || 'no'} </h2>
                        </div>
                            
                            {/* <button onClick={() => setAlertCount(alertCount + 1)} className="btn btn-primary">alert</button> */}
                        </div>
                        {setMedia()}
                    </>)
                    :
                    message}
                <h3> browser media support {MEDIA_SUPPORT.toString()}</h3>
                <button onClick={() => { }} className="btn btn-primary">refresh</button>
            </main>
            {MEDIA_SUPPORT &&
            (<audio src={sound} autoPlay={true} loop={true}></audio>)}
        </Layout>
    )
}

const CheckGuildId = (location, isLogged, me, setValid, setGuild) => {
    const route_id = location.pathname.split('/dashboard/music/')[1];
    if (route_id?.length !== 18) return setValid(false);
    if (!isLogged) return setValid(false);
    me.mutualGuilds.map(guild => {
        if (guild.id === route_id) {
            setValid(true);
            setGuild(guild);
            return
        }
    })

}

export default AnyGuildMusicPage